.modalBody {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2500;
    margin: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    place-items: center;
    flex-direction: column;
    padding: 0;
    background: rgba(0,0,0,0.5);
}
.closeModal{
    position: absolute;
    top: 0;
    right: 0;
    background: red;
}