.loginForm{
    padding-top: 20vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    vertical-align: middle;
}
.loginForm .input, .loginForm button{
    width: 30%;
    height: 30px;
    margin-bottom: 12px;
    display: block;
}