.panelView {
    display: flex;
    flex-direction: row;
}

.sidebar {
    border-right: 2px solid yellowgreen;
    width: 250px;
    height: calc(100vh - 50px);
    position: sticky;
    top: 50px;
    left: 0;
}

.dataContainer {
    min-height: 200vh;
    background: #280e3b;
    width: 100%;
}

.productTitle {
    color: yellowgreen;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 10px 20px;
    font-size: 20px;
    border-top: 1px solid lightgray;
}

.productTitle a {
    color: yellowgreen;
    float: right;
    font-size: 12px;
}

.remainingList {
    overflow-y: scroll;
    height: 550px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 14px;
}

.supplyMore {
    color: red;
}

.supplySoon {
    color: orange;
}

.hasEnough {
    color: yellowgreen;
}

.used {
    background: rgba(255, 201, 0, 0.42);
}

.not-used {

}

.textAction {
    display: inline-block;
    padding: 0 0 0 15px;
    font-size: 18px;
}

.progressData {
    border: 2px solid green;
    margin-left: 10px;
}

.weightInput {
    width: 50px;
    font-size: 20px;
    background: black;
    color: white;
    text-align: center;
    -moz-appearance: textfield;
}

.weightInput::-webkit-outer-spin-button,
.weightInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.activeProd{
    background: rgba(173, 255, 47, 0.37);
}
.inactiveProd{
    background: rgba(255, 165, 0, 0.37);
}

table{
    width: 100%;
    text-align: center;
}

.tableHeader{
    background: #282c33;
    padding: 5px;
}
table input[type=number]{
    width: 50px;
}