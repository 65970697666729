@font-face {
    font-family: 'Arial Unicode MS';
    src: url('../public/Arial-Unicode-MS.ttf') format('truetype');
}


body {
    margin: 0;
    background: #282c33;
    color: white;
    font-family: 'Arial Unicode MS', sans-serif !important;
}

.redBtn {
    background: darkred;
    color: white;
}

.orangeBtn {
    background: orange;
}

.greenBtn {
    background: darkgreen;
    color: white;
}

.socBoxBtn {
    width: fit-content;
    border: 2px solid #959595;
    padding: 6px;
    cursor: pointer;
}

.socBoxBtn.active {
    border-color: yellowgreen;
}

.duplicate {
    color: red!important;
    cursor: not-allowed;
    border-color: darkred;
}


.socBoxInput {
    border: 2px solid #959595;
    color: white;
    font-size: 20px;
    padding: 8px;
    width: 150px;
    background: #282c33;
}

.socBoxSelect {
    border: 2px solid #959595;
    color: white;
    font-size: 20px;
    padding: 8px;
    width: 250px;
    background: #282c33;
}

.sidebarBtn {
    width: 235px;
    margin: 10px 0;
}

.socBoxInput:focus {
    border-color: yellowgreen;
    outline: 0;
}

.socBoxTextarea {
    border: 2px solid #959595;
    color: white;
    font-size: 12px;
    padding: 0;
    width: 300px;
    background: #282c33;
}

.socBoxTextarea:focus {
    border-color: yellowgreen;
    outline: 0;
}

.socBoxLabel {
    border: 1px solid #959595;
    padding: 6px 3px;
    margin: 0;
    min-width: fit-content;
    overflow: visible;
}

.socBoxInput.short {
    width: 35px;
}


.fullWidth {
    width: 100%
}

.socBoxInput.fullWidth {
    width: calc(100% - 20px);
}

.socBoxTextarea.fullWidth {
    width: calc(100% - 5px);
}