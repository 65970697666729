.bigLoader {
    margin:  100px auto;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid yellowgreen; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}

.smallLoader{
    margin:  10px auto;
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid yellowgreen; /* Blue */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}